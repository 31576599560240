.default {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
    max-width: calc(568px + calc(var(--default-indent) * 2));
    width: 100%;
    position: relative;
    margin: 0 auto;
}

.withHeader {
    padding-top: 88px;
    padding-bottom: 32px;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.withBigHeader {
    padding-top: 106px;
    padding-bottom: 32px;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.withFooter {
    padding-top: 32px;
    padding-bottom: 60px;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.withoutHeader {
    padding-top: 0;
    padding-bottom: 32px;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.full {
    padding-top: 92px;
    padding-bottom: 60px;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.withHeader24PX {
    padding-top: 80px;
    padding-bottom: 32px;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.withHeader28PX {
    padding-top: 84px;
    padding-bottom: 32px;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.withBar32PX {
    padding-top: 100px;
    padding-bottom: 32px;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.withoutHeaderAndWithDynamicTopPadding {
    padding-top: 16px;
    padding-bottom: 32px;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.emailConsentFlow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.smallTopPadding {
    padding-top: 64px;
}

.withoutIndent {
    padding-left: 0;
    padding-right: 0;
}

.withoutFooter {
    padding-bottom: 0;
}

.withBigBottomPadding {
    padding-bottom: 120px;
}

.whiteFlow {
    background-color: var(--white-color) !important;
    min-height: 100vh;
}

@media (min-width: 414px) {
    .withoutHeaderAndWithDynamicTopPadding {
        padding-top: 32px;
    }
}
