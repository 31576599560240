.imgWrapper {
    width: 90px;
    margin: 10px auto 0;
}

.buttonBox {
    max-width: 400px;
    margin: 0 auto;
}

.mainButton {
    background-color: var(--primary-default) !important;
}
