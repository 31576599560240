.logo {
    position: relative;
    color: var(--white-color);
    display: flex;
    align-items: center;
}

.logoImgFooter {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.logoImgHeader {
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

.smallLogo {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.smallText {
    font-size: 8px;
    line-height: 10px;
}

.whiteTheme > div > h3 {
    color: var(--background-color);
}
