.header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--card-color);
    z-index: var(--z-index-header);
    transition: 0.2s;
}

.whiteTheme {
    background-color: var(--white-color) !important;
}

.headerContainer {
    padding: 0 var(--default-indent);
    min-height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.float_header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: var(--z-index-box);
}
.float {
    position: fixed;
    top: 0;
    width: 100%;
}

/*@TODO refactor price_line to some more suitable*/
.priceLine {
    position: fixed;
    width: 100%;
    left: 0;
    top: var(--header-height);
    padding: 4px 20px;
    background: var(--discount-bg-color);
}

.timerOff {
    position: fixed;
    width: 100%;
    left: 0;
    top: var(--header-height);
    padding: 4px 20px;
    background: var(--icon-color);
}

.hide_header {
    top: -160px;
    transition: 0.3s;
}

.show_header .headerContainer:first-child div {
    visibility: hidden;
}

.show_header {
    top: 0;
    transition: 0.3s;
}

.timer {
    display: flex;
    justify-content: center;
    background: var(--discount-bg-color);
    padding: 6px 0;
    margin: 0 -16px 24px -16px;
}
.timerEnd {
    visibility: hidden;
}

.floatTimer span > span {
    visibility: hidden;
}

.logInButtonBox {
    text-align: right;
}

.leftBlock {
    display: flex;
    align-items: center;
}

.leftBlock > *:not(:last-child) {
    margin-right: var(--default-indent);
}

.rightBlock {
    display: flex;
    align-items: center;
}

.rightBlock > *:not(:last-child) {
    margin-left: var(--default-indent);
}

.headerButtonLoader {
    position: relative;
    background: var(--brand-primary);
    border-radius: 12px;
    width: 162px;
    display: block;
    font-weight: 700;
    text-align: center;
    color: var(--brand-primary);
    min-height: 32px;
}

.headerButtonLoader:after {
    content: '';
    position: absolute;
    top: calc(50% - 11px);
    left: calc(50% - 9px);
    width: 20px;
    min-height: 20px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-left: 2px solid var(--white-color);
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
    z-index: var(--z-index-icon);
}

.getPlanButton {
    border-radius: var(--small-border-radius);
    background: var(--brand-primary);
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--white-color);
    min-width: 162px;
    max-width: 162px;
    padding: 4px 0;
}

.withShadow {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.3));
}

.maxZIndex {
    z-index: var(--z-index-headerOverlay);
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (min-width: 1024px) {
    .headerContainer {
        padding: 0 24px;
    }
}
