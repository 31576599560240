.customButton {
    border: 1px solid var(--border-default);
}

.customButton:disabled {
    background-color: var(--surface-default) !important;
}

.customButton:active {
    background-color: var(--surface-default) !important;
}
