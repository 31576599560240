.image {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
}

.fullHeight * {
    height: 100%;
}

.fullHeight img {
    width: auto;
}
