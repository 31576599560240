.h1 {
    font-weight: 700;
    font-size: 57px;
    line-height: 56px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    text-transform: uppercase;
}

.h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
}

.h4 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    text-transform: uppercase;
}

.h5 {
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.default {
    color: var(--white-color);
}

.grey {
    color: var(--icon-color);
}

.centered {
    text-align: center;
}

.withoutTextTransform {
    text-transform: none;
}

.lowerCase {
    text-transform: lowercase;
}

.warning {
    color: var(--warning-color);
}

.black {
    color: var(--surface-main);
}

@media (min-width: 768px) {
    .h2 {
        font-weight: 700;
        font-size: 46px;
        line-height: 52px;
        letter-spacing: 1px;
    }
}
